import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTheme } from 'emotion-theming';
import { css } from '@emotion/core';

import {
  Pump,
  Valve,
  Pipe,
  PipePlaceholder,
  IRCompressor,
  ValveConnect,
  Pond,
  MiddlePipeL,
  WaterTank,
  AirTank,
  Compressor,
  TriplePipeFork,
  DoublePipeFork,
  PipeFork,
  ConnectionIndicator,
  Aftercooler,
  AftercoolerFan,
} from 'isno/lib/components/equipment';

import {
  BasicRow,
  InfoControlRow,
  PumpsRowCustom,
  PumpsRow,
  PumpsRowSmall,
  PumpsRowExtraSmall,
  LabeledContainer,
} from 'isno/lib/components/layout';

import {
  Weather,
  Info,
  MultiInfo,
  // Totals,
  Statistic,
  MobileStatTable,
  PLCStateRouter,
  PLCColorRouter,
  LoadingIndicator,
} from 'isno/lib/components/displays';

import {
  AutoValveControl,
  VFDPumpControl,
  InfoControl,
  ToggleControl,
  AlarmReset,
  AftercoolerPID,
  MultiSetpointControl,
} from 'isno/lib/components/controls';

import { startCase } from 'lodash';

import PLC from 'isno/lib/static/images/PLC.png';
import { LanguageContext } from 'isno/lib/languages/LanguageContext';
import { fetchLocation, setIOValue, fetchPLCs } from '../actions';

// Should maintain one large key that I pass in to each component
// and can configure from pumphouse to pumphouse
const IOValueKeys = {
  // Building Data
  buildingTemp: 'building_temperature',
  // Water Flow Source
  cityTankMode: 'city_tank_mode',
  // Water Line Data
  waterFlowRate: 'water_flow',
  suctionWaterPressure: 'suction_water_pressure',
  line3WaterFlow: 'line_3_water_flow',
  line2WaterFlow: 'line_2_water_flow',
  // Air Line Data
  line2AirFlow: 'line_2_air_flow',
  line2AirTemp: 'line_2_air_temperature',
  line3AirFlow: 'line_3_air_flow',
  line3AirTemp: 'line_3_air_temperature',
  dischargeAirPressure: 'discharge_air_pressure',
  // Pump Data
  speed: 'speed',
  dischargePressure: 'discharge_water_pressure',
  dischargeTemperature: 'discharge_water_temperature',
  amps: 'current',
  hours: 'run_time',
  // Pump Alarms
  warning: 'warning',
  fault: 'fault',
  lowAmps: 'low_amps',
  masterAlarm: 'master_alarm',
  lowSuctionPressure: 'low_suction_pressure',
  emergencyStop: 'emergency_stop',
  // Valve Control
  openClose: 'open_close',
  // Valve Alarms
  // v1OpeningError: 'V1_opening_error',
  // v1ClosingError: 'V1_closing_error',
  // compressor IOValues
  bypassValve: 'bypass_valve',
  inletValve: 'inlet_valve',
  systemPressure: 'system_pressure',
  oilPressure: 'oil_pressure',
  oilTemperature: 'oil_temperature',
  stage1Temperature: 'stage1_temperature',
  stage1Pressure: 'stage1_pressure',
  stage1Vibration: 'stage1_vibration',
  stage2Temperature: 'stage2_temperature',
  stage2Pressure: 'stage2_pressure',
  stage2Vibration: 'stage2_vibration',
  stage3Temperature: 'stage3_temperature',
  stage3Pressure: 'stage3_pressure',
  stage3Vibration: 'stage3_vibration',
  compressorStartCommand: 'compressor_start_command',
  compressorStopCommand: 'compressor_stop_command',
  compressorModulate: 'compressor_modulate',
  compressorModulateCommand: 'compressor_modulate_command',
  compressorUnloaded: 'compressor_unloaded',
  compressorUnloadCommand: 'compressor_unload_command',
  compressorResetCommand: 'compressor_reset_command',
  systemPressureSetpoint: 'system_pressure_setpoint',
  compressorPressureSetpointCommand: 'compressor_pressure_setpoint_command',
};

function SnowflakeScreen(props) {
  const theme = useTheme();
  const { language } = useContext(LanguageContext);
  const { navbarClosed } = props;
  const styles = stylesFromTheme(theme, { navbarClosed });

  let oneSecondIntervalId;

  const oneSecondInterval = () => {
    oneSecondIntervalId = setTimeout(async () => {
      const promises = [
        props.fetchLocation(1),
        props.fetchLocation(2),
        props.fetchPLCs(),
      ];
      await Promise.all(promises);
      oneSecondIntervalId = setTimeout(oneSecondInterval, 1000);
    }, 1000);
  };

  useEffect(() => {
    // Async function to asynchronously request all of our data but then wait for
    // all of the responses before sending the next request. We use
    // this to make sure we aren't flooding our server with requests.
    props.fetchLocation(1);
    props.fetchLocation(2);
    props.fetchPLCs();
    oneSecondInterval();
    return () => {
      clearTimeout(oneSecondIntervalId);
    };
  }, []);

  const locationequipment1 = props.locations?.locations?.[1]?.equipment;
  const locationequipment2 = props.locations?.locations?.[2]?.equipment;
  if (!props.locations?.locations?.[1] || !props.locations?.locations?.[2]) {
    return (
      <div
        css={[
          styles.loadingContainer,
          props.navbarClosed ? styles.navClosedPadding : styles.navOpenPadding,
        ]}
      >
        <div css={css`position: relative; width: 100%; height: 100%;`}>
          <LoadingIndicator visible zIndex={4} />
        </div>
      </div>
    );
  }

  // TODO: fix style names... maybe use styled components
  return (
    <div css={[styles.fullscreen, styles.hideScrollbar]}>
      <div css={css`min-height: 0px;`}>
        <BasicRow styles="min-width: fit-content; min-height: fit-content;">
          <LabeledContainer height="fit-content" flex="3" border label="Snowflake Factory">
            <InfoControlRow>
              <MultiSetpointControl
                IOValueIDs={[`${locationequipment1?.CP1?.ioValues?.manual_setpoint?.id}`]}
                statistics={[`${locationequipment1?.CP1?.ioValues?.manual_setpoint?.value}`]}
                labels={['Manual Setpoint:']}
                units={[locationequipment1?.CP1?.ioValues?.manual_setpoint?.ioValueType?.units]}
                setIOValue={props.setIOValue}
                title="CP1 VFD Control"
              />
              <VFDPumpControl
                title="Building Fan Control"
                pumpData={{ id: `aftercoolerFan${locationequipment1?.['Building Fan PID']?.id}` }}
                pidData={locationequipment1?.['Building Fan PID']}
                setIOValue={props.setIOValue}
                max="1500"
                IOValueKeys={{
                  autoManual: 'auto_manual',
                  autoSetpoint: 'auto_setpoint',
                  manualSetpoint: 'manual_setpoint',
                  autoMode: 'auto_manual',
                  manualMode: 'auto_manual',
                }}
                readValues={[0, 1]}
                writeValues={[0, 1]}
                SP
              />
              <VFDPumpControl
                title="AC1-AC3 VFD Control"
                pumpData={{ id: `aftercoolerFan${locationequipment1?.['AC1-AC3 PID']?.id}` }}
                pidData={locationequipment1?.['AC1-AC3 PID']}
                setIOValue={props.setIOValue}
                max="1500"
                IOValueKeys={{
                  autoManual: 'auto_manual',
                  autoSetpoint: 'auto_setpoint',
                  manualSetpoint: 'manual_setpoint',
                  autoMode: 'auto_manual',
                  manualMode: 'auto_manual',
                }}
                readValues={[0, 1]}
                writeValues={[0, 1]}
                SP
              />
              <VFDPumpControl
                title="AC4-AC5 VFD Control"
                pumpData={{ id: `aftercoolerFan${locationequipment1?.['AC4-AC5 PID']?.id}` }}
                pidData={locationequipment1?.['AC4-AC5 PID']}
                setIOValue={props.setIOValue}
                max="1500"
                IOValueKeys={{
                  autoManual: 'auto_manual',
                  autoSetpoint: 'auto_setpoint',
                  manualSetpoint: 'manual_setpoint',
                  autoMode: 'auto_manual',
                  manualMode: 'auto_manual',
                }}
                readValues={[0, 1]}
                writeValues={[0, 1]}
                SP
              />
              <VFDPumpControl
                title="AC6-AC7 VFD Control"
                pumpData={{ id: `aftercoolerFan${locationequipment1?.['AC6-AC7 PID']?.id}` }}
                pidData={locationequipment1?.['AC6-AC7 PID']}
                setIOValue={props.setIOValue}
                max="1500"
                IOValueKeys={{
                  autoManual: 'auto_manual',
                  autoSetpoint: 'auto_setpoint',
                  manualSetpoint: 'manual_setpoint',
                  autoMode: 'auto_manual',
                  manualMode: 'auto_manual',
                }}
                readValues={[0, 1]}
                writeValues={[0, 1]}
                SP
              />
              <VFDPumpControl
                title="AC8-AC9 VFD Control"
                pumpData={{ id: `aftercoolerFan${locationequipment1?.['AC8-AC9 PID']?.id}` }}
                pidData={locationequipment1?.['AC8-AC9 PID']}
                setIOValue={props.setIOValue}
                max="1500"
                IOValueKeys={{
                  autoManual: 'auto_manual',
                  autoSetpoint: 'auto_setpoint',
                  manualSetpoint: 'manual_setpoint',
                  autoMode: 'auto_manual',
                  manualMode: 'auto_manual',
                }}
                readValues={[0, 1]}
                writeValues={[0, 1]}
                SP
              />
              <VFDPumpControl
                title="AC10-AC11 VFD Control"
                pumpData={{ id: `aftercoolerFan${locationequipment1?.['AC10-AC11 PID']?.id}` }}
                pidData={locationequipment1?.['AC10-AC11 PID']}
                setIOValue={props.setIOValue}
                max="1500"
                IOValueKeys={{
                  autoManual: 'auto_manual',
                  autoSetpoint: 'auto_setpoint',
                  manualSetpoint: 'manual_setpoint',
                  autoMode: 'auto_manual',
                  manualMode: 'auto_manual',
                }}
                readValues={[0, 1]}
                writeValues={[0, 1]}
                SP
              />
              <Info
                img={PLC}
                title="PLC Status"
                statistic={PLCStateRouter(props.plcs?.plcs?.[4])} // plcId 4: compressor building
                label=""
                color={PLCColorRouter(props.plcs?.plcs?.[4], theme)}
              />
              <Info
                title="Room Temp"
                statistic={locationequipment1?.Other?.ioValues?.building_temperature?.value}
                label={locationequipment1?.Other?.ioValues?.building_temperature?.ioValueType?.units}
                precision={1}
              />
              <AlarmReset
                title="System Reset"
                buttonText="Reset"
                alarmData={locationequipment1?.Other}
                setIOValue={props.setIOValue}
                alarmKeys={{
                  masterAlarm: 'master_alarm',
                  masterAlarmReset: 'master_alarm_reset',
                }}
                resetWriteValue={1}
              />
            </InfoControlRow>
            <PumpsRowCustom height="126px">
              <IRCompressor
                minWidth="206px"
                pipeTypeBelow={[false, true, false]}
                compressorData={locationequipment1.C1}
                location={props.locations?.locations?.[1]?.name}
                pipeAbove="right"
                controlPositionTop="-33px"
                controlPositionLeft="2px"
                pipeColorAbove={theme.pipeColors.air}
                pipeColorBelow={theme.pipeColors.air}
                setIOValue={props.setIOValue}
                IOValueKeys={IOValueKeys}
                writeValues={[1, 1]}
                readValues={[1, 0]}
                stateKey={{
                  0: language.off,
                  1: language.on,
                }}
                stateColorKey={{
                  0: theme.secondary,
                  1: theme.onGreen,
                }}
                statuses={[
                  {
                    id: locationequipment1?.C1?.id,
                    label: 'Status',
                    status: locationequipment1?.C1?.textStatusKey,
                    statusKey: {
                      0: '',
                      1: 'Ready',
                      2: 'Unloaded',
                      3: 'Loaded',
                      4: 'Alarm',
                    },
                    statusColorKey: {
                      0: theme.auto,
                      1: theme.onGreen,
                      2: theme.autoStop,
                      3: theme.local,
                      4: theme.alarm,
                    },
                  },
                ]}
                stats={[
                  {
                    stat: locationequipment1?.C1?.ioValues?.[IOValueKeys.amps]?.value,
                    label: locationequipment1?.C1?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                  },
                  {
                    stat: locationequipment1?.C1?.ioValues?.[IOValueKeys.hours]?.value,
                    label: locationequipment1?.C1?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                  },
                ]}
                column1Info={{
                  statistics: [
                    locationequipment1?.C1?.ioValues?.oil_temperature?.value,
                    locationequipment1?.C1?.ioValues?.oil_pressure?.value,
                    locationequipment1?.C1?.ioValues?.inlet_valve?.value,
                  ],
                  subtitles: [
                    'Oil Temp',
                    'Oil Pressure',
                    'Inlet Valve',
                  ],
                  // ['Psi', 'Amp', 'Psi', '°F'], // May need these explicit units for better formatting
                  labels: [
                    locationequipment1?.C1?.ioValues?.oil_temperature?.ioValueType?.units,
                    locationequipment1?.C1?.ioValues?.oil_pressure?.ioValueType?.units,
                    locationequipment1?.C1?.ioValues?.inlet_valve?.ioValueType?.units,
                  ],
                  ids: [
                    locationequipment1?.C1?.ioValues?.oil_temperature?.id,
                    locationequipment1?.C1?.ioValues?.oil_pressure?.id,
                    locationequipment1?.C1?.ioValues?.inlet_valve?.id,
                  ],
                  precisions: [1, 0, 0],
                  mappings: [undefined, undefined, undefined],
                }}
                column2Info={{
                  statistics: [
                    locationequipment1?.C1?.ioValues?.[IOValueKeys.pressureSetpoint]?.value,
                    locationequipment1?.C1?.ioValues?.system_pressure?.value,
                    locationequipment1?.C1?.ioValues?.bypass_valve?.value,
                  ],
                  subtitles: [
                    'Psi Setpoint',
                    'System Pressure',
                    'Bypass Valve',
                  ],
                  // ['Psi', 'Amp', 'Psi', '°F'], // May need these explicit units for better formatting
                  labels: [
                    locationequipment1?.C1?.ioValues?.[IOValueKeys.pressureSetpoint]?.ioValueType?.units,
                    locationequipment1?.C1?.ioValues?.system_pressure?.ioValueType?.units,
                    locationequipment1?.C1?.ioValues?.bypass_valve?.ioValueType?.units,
                  ],
                  ids: [
                    locationequipment1?.C1?.ioValues?.[IOValueKeys.pressureSetpoint]?.id,
                    locationequipment1?.C1?.ioValues?.system_pressure?.id,
                    locationequipment1?.C1?.ioValues?.bypass_valve?.id,
                  ],
                  precisions: [0, 0, 0],
                  mappings: [undefined, undefined, undefined],
                }}
              />
              <IRCompressor
                minWidth="206px"
                pipeTypeBelow={[false, true, false]}
                compressorData={locationequipment1.C2}
                location={props.locations?.locations?.[1]?.name}
                pipeAbove="middle"
                controlPositionTop="-33px"
                controlPositionLeft="2px"
                pipeColorAbove={theme.pipeColors.air}
                pipeColorBelow={theme.pipeColors.air}
                setIOValue={props.setIOValue}
                IOValueKeys={IOValueKeys}
                writeValues={[1, 1]}
                readValues={[1, 0]}
                stateKey={{
                  0: language.off,
                  1: language.on,
                }}
                stateColorKey={{
                  0: theme.secondary,
                  1: theme.onGreen,
                }}
                statuses={[
                  {
                    id: locationequipment1?.C2?.id,
                    label: 'Status',
                    status: locationequipment1?.C2?.textStatusKey,
                    statusKey: {
                      0: '',
                      1: 'Ready',
                      2: 'Unloaded',
                      3: 'Loaded',
                      4: 'Alarm',
                    },
                    statusColorKey: {
                      0: theme.auto,
                      1: theme.onGreen,
                      2: theme.autoStop,
                      3: theme.local,
                      4: theme.alarm,
                    },
                  },
                ]}
                stats={[
                  {
                    stat: locationequipment1?.C2?.ioValues?.[IOValueKeys.amps]?.value,
                    label: locationequipment1?.C2?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                  },
                  {
                    stat: locationequipment1?.C2?.ioValues?.[IOValueKeys.hours]?.value,
                    label: locationequipment1?.C2?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                  },
                ]}
                column1Info={{
                  statistics: [
                    locationequipment1?.C2?.ioValues?.oil_temperature?.value,
                    locationequipment1?.C2?.ioValues?.oil_pressure?.value,
                    locationequipment1?.C2?.ioValues?.inlet_valve?.value,
                  ],
                  subtitles: [
                    'Oil Temp',
                    'Oil Pressure',
                    'Inlet Valve',
                  ],
                  // ['Psi', 'Amp', 'Psi', '°F'], // May need these explicit units for better formatting
                  labels: [
                    locationequipment1?.C2?.ioValues?.oil_temperature?.ioValueType?.units,
                    locationequipment1?.C2?.ioValues?.oil_pressure?.ioValueType?.units,
                    locationequipment1?.C2?.ioValues?.inlet_valve?.ioValueType?.units,
                  ],
                  ids: [
                    locationequipment1?.C2?.ioValues?.oil_temperature?.id,
                    locationequipment1?.C2?.ioValues?.oil_pressure?.id,
                    locationequipment1?.C2?.ioValues?.inlet_valve?.id,
                  ],
                  precisions: [1, 0, 0],
                  mappings: [undefined, undefined, undefined],
                }}
                column2Info={{
                  statistics: [
                    locationequipment1?.C2?.ioValues?.[IOValueKeys.pressureSetpoint]?.value,
                    locationequipment1?.C2?.ioValues?.system_pressure?.value,
                    locationequipment1?.C2?.ioValues?.bypass_valve?.value,
                  ],
                  subtitles: [
                    'Psi Setpoint',
                    'System Pressure',
                    'Bypass Valve',
                  ],
                  // ['Psi', 'Amp', 'Psi', '°F'], // May need these explicit units for better formatting
                  labels: [
                    locationequipment1?.C2?.ioValues?.[IOValueKeys.pressureSetpoint]?.ioValueType?.units,
                    locationequipment1?.C2?.ioValues?.system_pressure?.ioValueType?.units,
                    locationequipment1?.C2?.ioValues?.bypass_valve?.ioValueType?.units,
                  ],
                  ids: [
                    locationequipment1?.C2?.ioValues?.[IOValueKeys.pressureSetpoint]?.id,
                    locationequipment1?.C2?.ioValues?.system_pressure?.id,
                    locationequipment1?.C2?.ioValues?.bypass_valve?.id,
                  ],
                  precisions: [0, 0, 0],
                  mappings: [undefined, undefined, undefined],
                }}
              />
              <IRCompressor
                minWidth="206px"
                pipeTypeBelow={[false, true, false]}
                compressorData={locationequipment1.C3}
                location={props.locations?.locations?.[1]?.name}
                pipeAbove="middle"
                controlPositionTop="-33px"
                controlPositionLeft="2px"
                pipeColorAbove={theme.pipeColors.air}
                pipeColorBelow={theme.pipeColors.air}
                setIOValue={props.setIOValue}
                IOValueKeys={IOValueKeys}
                writeValues={[1, 1]}
                readValues={[1, 0]}
                stateKey={{
                  0: language.off,
                  1: language.on,
                }}
                stateColorKey={{
                  0: theme.secondary,
                  1: theme.onGreen,
                }}
                statuses={[
                  {
                    id: locationequipment1?.C3?.id,
                    label: 'Status',
                    status: locationequipment1?.C3?.textStatusKey,
                    statusKey: {
                      0: '',
                      1: 'Ready',
                      2: 'Unloaded',
                      3: 'Loaded',
                      4: 'Alarm',
                    },
                    statusColorKey: {
                      0: theme.auto,
                      1: theme.onGreen,
                      2: theme.autoStop,
                      3: theme.local,
                      4: theme.alarm,
                    },
                  },
                ]}
                stats={[
                  {
                    stat: locationequipment1?.C3?.ioValues?.[IOValueKeys.amps]?.value,
                    label: locationequipment1?.C3?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                  },
                  {
                    stat: locationequipment1?.C3?.ioValues?.[IOValueKeys.hours]?.value,
                    label: locationequipment1?.C3?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                  },
                ]}
                column1Info={{
                  statistics: [
                    locationequipment1?.C3?.ioValues?.oil_temperature?.value,
                    locationequipment1?.C3?.ioValues?.oil_pressure?.value,
                    locationequipment1?.C3?.ioValues?.inlet_valve?.value,
                  ],
                  subtitles: [
                    'Oil Temp',
                    'Oil Pressure',
                    'Inlet Valve',
                  ],
                  // ['Psi', 'Amp', 'Psi', '°F'], // May need these explicit units for better formatting
                  labels: [
                    locationequipment1?.C3?.ioValues?.oil_temperature?.ioValueType?.units,
                    locationequipment1?.C3?.ioValues?.oil_pressure?.ioValueType?.units,
                    locationequipment1?.C3?.ioValues?.inlet_valve?.ioValueType?.units,
                  ],
                  ids: [
                    locationequipment1?.C3?.ioValues?.oil_temperature?.id,
                    locationequipment1?.C3?.ioValues?.oil_pressure?.id,
                    locationequipment1?.C3?.ioValues?.inlet_valve?.id,
                  ],
                  precisions: [1, 0, 0],
                  mappings: [undefined, undefined, undefined],
                }}
                column2Info={{
                  statistics: [
                    locationequipment1?.C3?.ioValues?.[IOValueKeys.pressureSetpoint]?.value,
                    locationequipment1?.C3?.ioValues?.system_pressure?.value,
                    locationequipment1?.C3?.ioValues?.bypass_valve?.value,
                  ],
                  subtitles: [
                    'Psi Setpoint',
                    'System Pressure',
                    'Bypass Valve',
                  ],
                  // ['Psi', 'Amp', 'Psi', '°F'], // May need these explicit units for better formatting
                  labels: [
                    locationequipment1?.C3?.ioValues?.[IOValueKeys.pressureSetpoint]?.ioValueType?.units,
                    locationequipment1?.C3?.ioValues?.system_pressure?.ioValueType?.units,
                    locationequipment1?.C3?.ioValues?.bypass_valve?.ioValueType?.units,
                  ],
                  ids: [
                    locationequipment1?.C3?.ioValues?.[IOValueKeys.pressureSetpoint]?.id,
                    locationequipment1?.C3?.ioValues?.system_pressure?.id,
                    locationequipment1?.C3?.ioValues?.bypass_valve?.id,
                  ],
                  precisions: [0, 0, 0],
                  mappings: [undefined, undefined, undefined],
                }}
              />
              <div css={[styles.flexRow, css`height: 100%;`]}>
                <IRCompressor
                  minWidth="206px"
                  pipeTypeBelow={[false, true, false]}
                  compressorData={locationequipment1.C4}
                  location={props.locations?.locations?.[1]?.name}
                  pipeAbove="middle"
                  controlPositionTop="-33px"
                  controlPositionLeft="2px"
                  pipeColorAbove={theme.pipeColors.air}
                  pipeColorBelow={theme.pipeColors.air}
                  setIOValue={props.setIOValue}
                  IOValueKeys={IOValueKeys}
                  writeValues={[1, 1]}
                  readValues={[1, 0]}
                  stateKey={{
                    0: language.off,
                    1: language.on,
                  }}
                  stateColorKey={{
                    0: theme.secondary,
                    1: theme.onGreen,
                  }}
                  statuses={[
                    {
                      id: locationequipment1?.C4?.id,
                      label: 'Status',
                      status: locationequipment1?.C4?.textStatusKey,
                      statusKey: {
                        0: '',
                        1: 'Ready',
                        2: 'Unloaded',
                        3: 'Loaded',
                        4: 'Alarm',
                      },
                      statusColorKey: {
                        0: theme.auto,
                        1: theme.onGreen,
                        2: theme.autoStop,
                        3: theme.local,
                        4: theme.alarm,
                      },
                    },
                  ]}
                  stats={[
                    {
                      stat: locationequipment1?.C4?.ioValues?.[IOValueKeys.amps]?.value,
                      label: locationequipment1?.C4?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                    },
                    {
                      stat: locationequipment1?.C4?.ioValues?.[IOValueKeys.hours]?.value,
                      label: locationequipment1?.C4?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                    },
                  ]}
                  column1Info={{
                    statistics: [
                      locationequipment1?.C4?.ioValues?.oil_temperature?.value,
                      locationequipment1?.C4?.ioValues?.oil_pressure?.value,
                      locationequipment1?.C4?.ioValues?.inlet_valve?.value,
                    ],
                    subtitles: [
                      'Oil Temp',
                      'Oil Pressure',
                      'Inlet Valve',
                    ],
                    // ['Psi', 'Amp', 'Psi', '°F'], // May need these explicit units for better formatting
                    labels: [
                      locationequipment1?.C4?.ioValues?.oil_temperature?.ioValueType?.units,
                      locationequipment1?.C4?.ioValues?.oil_pressure?.ioValueType?.units,
                      locationequipment1?.C4?.ioValues?.inlet_valve?.ioValueType?.units,
                    ],
                    ids: [
                      locationequipment1?.C4?.ioValues?.oil_temperature?.id,
                      locationequipment1?.C4?.ioValues?.oil_pressure?.id,
                      locationequipment1?.C4?.ioValues?.inlet_valve?.id,
                    ],
                    precisions: [1, 0, 0],
                    mappings: [undefined, undefined, undefined],
                  }}
                  column2Info={{
                    statistics: [
                      locationequipment1?.C4?.ioValues?.[IOValueKeys.pressureSetpoint]?.value,
                      locationequipment1?.C4?.ioValues?.system_pressure?.value,
                      locationequipment1?.C4?.ioValues?.bypass_valve?.value,
                    ],
                    subtitles: [
                      'Psi Setpoint',
                      'System Pressure',
                      'Bypass Valve',
                    ],
                    // ['Psi', 'Amp', 'Psi', '°F'], // May need these explicit units for better formatting
                    labels: [
                      locationequipment1?.C4?.ioValues?.[IOValueKeys.pressureSetpoint]?.ioValueType?.units,
                      locationequipment1?.C4?.ioValues?.system_pressure?.ioValueType?.units,
                      locationequipment1?.C4?.ioValues?.bypass_valve?.ioValueType?.units,
                    ],
                    ids: [
                      locationequipment1?.C4?.ioValues?.[IOValueKeys.pressureSetpoint]?.id,
                      locationequipment1?.C4?.ioValues?.system_pressure?.id,
                      locationequipment1?.C4?.ioValues?.bypass_valve?.id,
                    ],
                    precisions: [0, 0, 0],
                    mappings: [undefined, undefined, undefined],
                  }}
                />
                <div css={css`position: absolute; top: -3px; right: -2px;`}>
                  <Statistic
                    label={locationequipment2?.Other?.ioValues?.discharge_air_pressure?.ioValueType?.units}
                    border
                    statistic={locationequipment2?.Other?.ioValues?.discharge_air_pressure?.value}
                  />
                </div>
                <div css={css`position: absolute; top: 18px; right: 28px;`}>
                  To
                </div>
                <div css={css`position: absolute; top: 18px; right: 4px;`}>
                  Hill
                </div>
              </div>
              <div css={[styles.flexRow, css`min-width: 356px; height: 100%; margin-left: 12px;`]}>
                <div css={css`width: 50%; height: 100%; margin-top: -14px;`}>
                  <AftercoolerFan
                    setIOValue={props.setIOValue}
                    imgStyle="height: 94px;"
                    controlPositionTop="-46px"
                    controlPositionLeft="-5px"
                    pipes={false}
                    fanData={{
                      ...locationequipment1?.['Building Fan'],
                      name: 'Bldg. Fan',
                      state: locationequipment1?.['Building Fan']?.status,
                      fanColor: locationequipment1?.['Building Fan']?.ioValues?.run_feedback?.value ? 'green' : 'red',
                    }}
                    statuses={[
                      {
                        id: locationequipment1?.['Building Fan'].ioValues?.fault?.id,
                        status: locationequipment1?.['Building Fan'].ioValues?.fault?.value,
                        statusKey: {
                          0: '',
                          1: 'Fault',
                        },
                        statusColorKey: {
                          0: theme.manual, // These aren't the "named" colors but I like them for this use case
                          1: theme.alarm,
                        },
                      },
                      {
                        id: locationequipment1?.['Building Fan'].ioValues?.auto?.id,
                        status: locationequipment1?.['Building Fan'].ioValues?.auto?.value,
                        statusKey: {
                          0: '',
                          1: 'Auto',
                        },
                        statusColorKey: {
                          0: '', // These aren't the "named" colors but I like them for this use case
                          1: theme.auto,
                        },
                      },
                    ]}
                  />
                </div>
                <div css={css`width: 50%; height: 100%; margin-top: -14px;`}>
                  <AftercoolerFan
                    setIOValue={props.setIOValue}
                    imgStyle="height: 94px;"
                    controlPositionTop="-46px"
                    controlPositionLeft="-5px"
                    pipes={false}
                    fanData={{
                      ...locationequipment1?.['AC1-AC3'],
                      state: locationequipment1?.['AC1-AC3']?.status,
                      fanColor: locationequipment1?.['AC1-AC3']?.ioValues?.run_feedback?.value ? 'green' : 'red',
                    }}
                    statuses={[
                      {
                        id: locationequipment1?.['AC1-AC3'].ioValues?.fault?.id,
                        status: locationequipment1?.['AC1-AC3'].ioValues?.fault?.value,
                        statusKey: {
                          0: '',
                          1: 'Fault',
                        },
                        statusColorKey: {
                          0: theme.manual, // These aren't the "named" colors but I like them for this use case
                          1: theme.alarm,
                        },
                      },
                      {
                        id: locationequipment1?.['AC1-AC3'].ioValues?.auto?.id,
                        status: locationequipment1?.['AC1-AC3'].ioValues?.auto?.value,
                        statusKey: {
                          0: '',
                          1: 'Auto',
                        },
                        statusColorKey: {
                          0: '', // These aren't the "named" colors but I like them for this use case
                          1: theme.auto,
                        },
                      },
                    ]}
                    stats={[
                      {
                        stat: locationequipment1?.['AC1-AC3']?.ioValues?.air_temperature?.value,
                        label: locationequipment1?.['AC1-AC3']?.ioValues?.air_temperature?.ioValueType?.units,
                        precision: 1,
                      },
                      {
                        stat: locationequipment1?.['AC1-AC3']?.ioValues?.[IOValueKeys.hours]?.value,
                        label: locationequipment1?.['AC1-AC3']?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                      },
                    ]}
                  />
                </div>
              </div>
            </PumpsRowCustom>
            <PumpsRowCustom height="126px">
              <IRCompressor
                minWidth="206px"
                pipeTypeBelow={[false, true, false]}
                pipeTypeAbove={[false, true, false]}
                compressorData={locationequipment1.C5}
                location={props.locations?.locations?.[1]?.name}
                controlPositionTop="-33px"
                controlPositionLeft="2px"
                pipeColorAbove={theme.pipeColors.air}
                pipeColorBelow={theme.pipeColors.air}
                setIOValue={props.setIOValue}
                IOValueKeys={IOValueKeys}
                writeValues={[1, 1]}
                readValues={[1, 0]}
                stateKey={{
                  0: language.off,
                  1: language.on,
                }}
                stateColorKey={{
                  0: theme.secondary,
                  1: theme.onGreen,
                }}
                statuses={[
                  {
                    id: locationequipment1?.C5?.id,
                    label: 'Status',
                    status: locationequipment1?.C5?.textStatusKey,
                    statusKey: {
                      0: '',
                      1: 'Ready',
                      2: 'Unloaded',
                      3: 'Loaded',
                      4: 'Alarm',
                    },
                    statusColorKey: {
                      0: theme.auto,
                      1: theme.onGreen,
                      2: theme.autoStop,
                      3: theme.local,
                      4: theme.alarm,
                    },
                  },
                ]}
                stats={[
                  {
                    stat: locationequipment1?.C5?.ioValues?.[IOValueKeys.amps]?.value,
                    label: locationequipment1?.C5?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                  },
                  {
                    stat: locationequipment1?.C5?.ioValues?.[IOValueKeys.hours]?.value,
                    label: locationequipment1?.C5?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                  },
                ]}
                column1Info={{
                  statistics: [
                    locationequipment1?.C5?.ioValues?.oil_temperature?.value,
                    locationequipment1?.C5?.ioValues?.oil_pressure?.value,
                    locationequipment1?.C5?.ioValues?.inlet_valve?.value,
                  ],
                  subtitles: [
                    'Oil Temp',
                    'Oil Pressure',
                    'Inlet Valve',
                  ],
                  // ['Psi', 'Amp', 'Psi', '°F'], // May need these explicit units for better formatting
                  labels: [
                    locationequipment1?.C5?.ioValues?.oil_temperature?.ioValueType?.units,
                    locationequipment1?.C5?.ioValues?.oil_pressure?.ioValueType?.units,
                    locationequipment1?.C5?.ioValues?.inlet_valve?.ioValueType?.units,
                  ],
                  ids: [
                    locationequipment1?.C5?.ioValues?.oil_temperature?.id,
                    locationequipment1?.C5?.ioValues?.oil_pressure?.id,
                    locationequipment1?.C5?.ioValues?.inlet_valve?.id,
                  ],
                  precisions: [1, 0, 0],
                  mappings: [undefined, undefined, undefined],
                }}
                column2Info={{
                  statistics: [
                    locationequipment1?.C5?.ioValues?.[IOValueKeys.pressureSetpoint]?.value,
                    locationequipment1?.C5?.ioValues?.system_pressure?.value,
                    locationequipment1?.C5?.ioValues?.bypass_valve?.value,
                  ],
                  subtitles: [
                    'Psi Setpoint',
                    'System Pressure',
                    'Bypass Valve',
                  ],
                  // ['Psi', 'Amp', 'Psi', '°F'], // May need these explicit units for better formatting
                  labels: [
                    locationequipment1?.C5?.ioValues?.[IOValueKeys.pressureSetpoint]?.ioValueType?.units,
                    locationequipment1?.C5?.ioValues?.system_pressure?.ioValueType?.units,
                    locationequipment1?.C5?.ioValues?.bypass_valve?.ioValueType?.units,
                  ],
                  ids: [
                    locationequipment1?.C5?.ioValues?.[IOValueKeys.pressureSetpoint]?.id,
                    locationequipment1?.C5?.ioValues?.system_pressure?.id,
                    locationequipment1?.C5?.ioValues?.bypass_valve?.id,
                  ],
                  precisions: [0, 0, 0],
                  mappings: [undefined, undefined, undefined],
                }}
              />
              <IRCompressor
                minWidth="206px"
                pipeTypeBelow={[false, false, false]}
                pipeTypeAbove={[false, true, false]}
                compressorData={locationequipment1.C6}
                location={props.locations?.locations?.[1]?.name}
                controlPositionTop="-33px"
                controlPositionLeft="2px"
                pipeColorAbove={theme.pipeColors.air}
                pipeColorBelow={theme.pipeColors.air}
                setIOValue={props.setIOValue}
                IOValueKeys={IOValueKeys}
                writeValues={[1, 1]}
                readValues={[1, 0]}
                stateKey={{
                  0: language.off,
                  1: language.on,
                }}
                stateColorKey={{
                  0: theme.secondary,
                  1: theme.onGreen,
                }}
                statuses={[
                  {
                    id: locationequipment1?.C6?.id,
                    label: 'Status',
                    status: locationequipment1?.C6?.textStatusKey,
                    statusKey: {
                      0: '',
                      1: 'Ready',
                      2: 'Unloaded',
                      3: 'Loaded',
                      4: 'Alarm',
                    },
                    statusColorKey: {
                      0: theme.auto,
                      1: theme.onGreen,
                      2: theme.autoStop,
                      3: theme.local,
                      4: theme.alarm,
                    },
                  },
                ]}
                stats={[
                  {
                    stat: locationequipment1?.C6?.ioValues?.[IOValueKeys.amps]?.value,
                    label: locationequipment1?.C6?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                  },
                  {
                    stat: locationequipment1?.C6?.ioValues?.[IOValueKeys.hours]?.value,
                    label: locationequipment1?.C6?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                  },
                ]}
                column1Info={{
                  statistics: [
                    locationequipment1?.C6?.ioValues?.oil_temperature?.value,
                    locationequipment1?.C6?.ioValues?.oil_pressure?.value,
                    locationequipment1?.C6?.ioValues?.inlet_valve?.value,
                  ],
                  subtitles: [
                    'Oil Temp',
                    'Oil Pressure',
                    'Inlet Valve',
                  ],
                  // ['Psi', 'Amp', 'Psi', '°F'], // May need these explicit units for better formatting
                  labels: [
                    locationequipment1?.C6?.ioValues?.oil_temperature?.ioValueType?.units,
                    locationequipment1?.C6?.ioValues?.oil_pressure?.ioValueType?.units,
                    locationequipment1?.C6?.ioValues?.inlet_valve?.ioValueType?.units,
                  ],
                  ids: [
                    locationequipment1?.C6?.ioValues?.oil_temperature?.id,
                    locationequipment1?.C6?.ioValues?.oil_pressure?.id,
                    locationequipment1?.C6?.ioValues?.inlet_valve?.id,
                  ],
                  precisions: [1, 0, 0],
                  mappings: [undefined, undefined, undefined],
                }}
                column2Info={{
                  statistics: [
                    locationequipment1?.C6?.ioValues?.[IOValueKeys.pressureSetpoint]?.value,
                    locationequipment1?.C6?.ioValues?.system_pressure?.value,
                    locationequipment1?.C6?.ioValues?.bypass_valve?.value,
                  ],
                  subtitles: [
                    'Psi Setpoint',
                    'System Pressure',
                    'Bypass Valve',
                  ],
                  // ['Psi', 'Amp', 'Psi', '°F'], // May need these explicit units for better formatting
                  labels: [
                    locationequipment1?.C6?.ioValues?.[IOValueKeys.pressureSetpoint]?.ioValueType?.units,
                    locationequipment1?.C6?.ioValues?.system_pressure?.ioValueType?.units,
                    locationequipment1?.C6?.ioValues?.bypass_valve?.ioValueType?.units,
                  ],
                  ids: [
                    locationequipment1?.C6?.ioValues?.[IOValueKeys.pressureSetpoint]?.id,
                    locationequipment1?.C6?.ioValues?.system_pressure?.id,
                    locationequipment1?.C6?.ioValues?.bypass_valve?.id,
                  ],
                  precisions: [0, 0, 0],
                  mappings: [undefined, undefined, undefined],
                }}
              />
              <IRCompressor
                minWidth="206px"
                pipeTypeBelow={[false, false, false]}
                pipeTypeAbove={[false, true, false]}
                compressorData={locationequipment1.C7}
                location={props.locations?.locations?.[1]?.name}
                controlPositionTop="-33px"
                controlPositionLeft="2px"
                pipeColorAbove={theme.pipeColors.air}
                pipeColorBelow={theme.pipeColors.air}
                setIOValue={props.setIOValue}
                IOValueKeys={IOValueKeys}
                writeValues={[1, 1]}
                readValues={[1, 0]}
                stateKey={{
                  0: language.off,
                  1: language.on,
                }}
                stateColorKey={{
                  0: theme.secondary,
                  1: theme.onGreen,
                }}
                statuses={[
                  {
                    id: locationequipment1?.C7?.id,
                    label: 'Status',
                    status: locationequipment1?.C7?.textStatusKey,
                    statusKey: {
                      0: '',
                      1: 'Ready',
                      2: 'Unloaded',
                      3: 'Loaded',
                      4: 'Alarm',
                    },
                    statusColorKey: {
                      0: theme.auto,
                      1: theme.onGreen,
                      2: theme.autoStop,
                      3: theme.local,
                      4: theme.alarm,
                    },
                  },
                ]}
                stats={[
                  {
                    stat: locationequipment1?.C7?.ioValues?.[IOValueKeys.amps]?.value,
                    label: locationequipment1?.C7?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                  },
                  {
                    stat: locationequipment1?.C7?.ioValues?.[IOValueKeys.hours]?.value,
                    label: locationequipment1?.C7?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                  },
                ]}
                column1Info={{
                  statistics: [
                    locationequipment1?.C7?.ioValues?.oil_temperature?.value,
                    locationequipment1?.C7?.ioValues?.oil_pressure?.value,
                    locationequipment1?.C7?.ioValues?.inlet_valve?.value,
                  ],
                  subtitles: [
                    'Oil Temp',
                    'Oil Pressure',
                    'Inlet Valve',
                  ],
                  // ['Psi', 'Amp', 'Psi', '°F'], // May need these explicit units for better formatting
                  labels: [
                    locationequipment1?.C7?.ioValues?.oil_temperature?.ioValueType?.units,
                    locationequipment1?.C7?.ioValues?.oil_pressure?.ioValueType?.units,
                    locationequipment1?.C7?.ioValues?.inlet_valve?.ioValueType?.units,
                  ],
                  ids: [
                    locationequipment1?.C7?.ioValues?.oil_temperature?.id,
                    locationequipment1?.C7?.ioValues?.oil_pressure?.id,
                    locationequipment1?.C7?.ioValues?.inlet_valve?.id,
                  ],
                  precisions: [1, 0, 0],
                  mappings: [undefined, undefined, undefined],
                }}
                column2Info={{
                  statistics: [
                    locationequipment1?.C7?.ioValues?.[IOValueKeys.pressureSetpoint]?.value,
                    locationequipment1?.C7?.ioValues?.system_pressure?.value,
                    locationequipment1?.C7?.ioValues?.bypass_valve?.value,
                  ],
                  subtitles: [
                    'Psi Setpoint',
                    'System Pressure',
                    'Bypass Valve',
                  ],
                  // ['Psi', 'Amp', 'Psi', '°F'], // May need these explicit units for better formatting
                  labels: [
                    locationequipment1?.C7?.ioValues?.[IOValueKeys.pressureSetpoint]?.ioValueType?.units,
                    locationequipment1?.C7?.ioValues?.system_pressure?.ioValueType?.units,
                    locationequipment1?.C7?.ioValues?.bypass_valve?.ioValueType?.units,
                  ],
                  ids: [
                    locationequipment1?.C7?.ioValues?.[IOValueKeys.pressureSetpoint]?.id,
                    locationequipment1?.C7?.ioValues?.system_pressure?.id,
                    locationequipment1?.C7?.ioValues?.bypass_valve?.id,
                  ],
                  precisions: [0, 0, 0],
                  mappings: [undefined, undefined, undefined],
                }}
              />
              <IRCompressor
                minWidth="206px"
                pipeTypeBelow={[false, false, false]}
                pipeTypeAbove={[false, true, false]}
                compressorData={locationequipment1.C8}
                location={props.locations?.locations?.[1]?.name}
                controlPositionTop="-33px"
                controlPositionLeft="2px"
                pipeColorAbove={theme.pipeColors.air}
                pipeColorBelow={theme.pipeColors.air}
                setIOValue={props.setIOValue}
                IOValueKeys={IOValueKeys}
                writeValues={[1, 1]}
                readValues={[1, 0]}
                stateKey={{
                  0: language.off,
                  1: language.on,
                }}
                stateColorKey={{
                  0: theme.secondary,
                  1: theme.onGreen,
                }}
                statuses={[
                  {
                    id: locationequipment1?.C8?.id,
                    label: 'Status',
                    status: locationequipment1?.C8?.textStatusKey,
                    statusKey: {
                      0: '',
                      1: 'Ready',
                      2: 'Unloaded',
                      3: 'Loaded',
                      4: 'Alarm',
                    },
                    statusColorKey: {
                      0: theme.auto,
                      1: theme.onGreen,
                      2: theme.autoStop,
                      3: theme.local,
                      4: theme.alarm,
                    },
                  },
                ]}
                stats={[
                  {
                    stat: locationequipment1?.C8?.ioValues?.[IOValueKeys.amps]?.value,
                    label: locationequipment1?.C8?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                  },
                  {
                    stat: locationequipment1?.C8?.ioValues?.[IOValueKeys.hours]?.value,
                    label: locationequipment1?.C8?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                  },
                ]}
                column1Info={{
                  statistics: [
                    locationequipment1?.C8?.ioValues?.oil_temperature?.value,
                    locationequipment1?.C8?.ioValues?.oil_pressure?.value,
                    locationequipment1?.C8?.ioValues?.inlet_valve?.value,
                  ],
                  subtitles: [
                    'Oil Temp',
                    'Oil Pressure',
                    'Inlet Valve',
                  ],
                  // ['Psi', 'Amp', 'Psi', '°F'], // May need these explicit units for better formatting
                  labels: [
                    locationequipment1?.C8?.ioValues?.oil_temperature?.ioValueType?.units,
                    locationequipment1?.C8?.ioValues?.oil_pressure?.ioValueType?.units,
                    locationequipment1?.C8?.ioValues?.inlet_valve?.ioValueType?.units,
                  ],
                  ids: [
                    locationequipment1?.C8?.ioValues?.oil_temperature?.id,
                    locationequipment1?.C8?.ioValues?.oil_pressure?.id,
                    locationequipment1?.C8?.ioValues?.inlet_valve?.id,
                  ],
                  precisions: [1, 0, 0],
                  mappings: [undefined, undefined, undefined],
                }}
                column2Info={{
                  statistics: [
                    locationequipment1?.C8?.ioValues?.[IOValueKeys.pressureSetpoint]?.value,
                    locationequipment1?.C8?.ioValues?.system_pressure?.value,
                    locationequipment1?.C8?.ioValues?.bypass_valve?.value,
                  ],
                  subtitles: [
                    'Psi Setpoint',
                    'System Pressure',
                    'Bypass Valve',
                  ],
                  // ['Psi', 'Amp', 'Psi', '°F'], // May need these explicit units for better formatting
                  labels: [
                    locationequipment1?.C8?.ioValues?.[IOValueKeys.pressureSetpoint]?.ioValueType?.units,
                    locationequipment1?.C8?.ioValues?.system_pressure?.ioValueType?.units,
                    locationequipment1?.C8?.ioValues?.bypass_valve?.ioValueType?.units,
                  ],
                  ids: [
                    locationequipment1?.C8?.ioValues?.[IOValueKeys.pressureSetpoint]?.id,
                    locationequipment1?.C8?.ioValues?.system_pressure?.id,
                    locationequipment1?.C8?.ioValues?.bypass_valve?.id,
                  ],
                  precisions: [0, 0, 0],
                  mappings: [undefined, undefined, undefined],
                }}
              />
              <div css={[styles.flexRow, css`min-width: 356px; height: 100%; margin-left: 12px;`]}>
                <div css={css`width: 50%; height: 100%; margin-top: -14px;`}>
                  <AftercoolerFan
                    setIOValue={props.setIOValue}
                    imgStyle="height: 94px;"
                    controlPositionTop="-46px"
                    controlPositionLeft="-5px"
                    pipes={false}
                    fanData={{
                      ...locationequipment1?.['AC4-AC5'],
                      state: locationequipment1?.['AC4-AC5']?.status,
                      fanColor: locationequipment1?.['AC4-AC5']?.ioValues?.run_feedback?.value ? 'green' : 'red',
                    }}
                    statuses={[
                      {
                        id: locationequipment1?.['AC4-AC5'].ioValues?.fault?.id,
                        status: locationequipment1?.['AC4-AC5'].ioValues?.fault?.value,
                        statusKey: {
                          0: '',
                          1: 'Fault',
                        },
                        statusColorKey: {
                          0: theme.manual, // These aren't the "named" colors but I like them for this use case
                          1: theme.alarm,
                        },
                      },
                      {
                        id: locationequipment1?.['AC4-AC5'].ioValues?.auto?.id,
                        status: locationequipment1?.['AC4-AC5'].ioValues?.auto?.value,
                        statusKey: {
                          0: '',
                          1: 'Auto',
                        },
                        statusColorKey: {
                          0: '', // These aren't the "named" colors but I like them for this use case
                          1: theme.auto,
                        },
                      },
                    ]}
                    stats={[
                      {
                        stat: locationequipment1?.['AC4-AC5']?.ioValues?.air_temperature?.value,
                        label: locationequipment1?.['AC4-AC5']?.ioValues?.air_temperature?.ioValueType?.units,
                        precision: 1,
                      },
                      {
                        stat: locationequipment1?.['AC4-AC5']?.ioValues?.[IOValueKeys.hours]?.value,
                        label: locationequipment1?.['AC4-AC5']?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                      },
                    ]}
                  />
                </div>
                <div css={css`width: 50%; height: 100%; margin-top: -14px;`}>
                  <AftercoolerFan
                    setIOValue={props.setIOValue}
                    imgStyle="height: 94px;"
                    controlPositionTop="-46px"
                    controlPositionLeft="-5px"
                    pipes={false}
                    fanData={{
                      ...locationequipment1?.['AC6-AC7'],
                      state: locationequipment1?.['AC6-AC7']?.status,
                      fanColor: locationequipment1?.['AC6-AC7']?.ioValues?.run_feedback?.value ? 'green' : 'red',
                    }}
                    statuses={[
                      {
                        id: locationequipment1?.['AC6-AC7'].ioValues?.fault?.id,
                        status: locationequipment1?.['AC6-AC7'].ioValues?.fault?.value,
                        statusKey: {
                          0: '',
                          1: 'Fault',
                        },
                        statusColorKey: {
                          0: theme.manual, // These aren't the "named" colors but I like them for this use case
                          1: theme.alarm,
                        },
                      },
                      {
                        id: locationequipment1?.['AC6-AC7'].ioValues?.auto?.id,
                        status: locationequipment1?.['AC6-AC7'].ioValues?.auto?.value,
                        statusKey: {
                          0: '',
                          1: 'Auto',
                        },
                        statusColorKey: {
                          0: '', // These aren't the "named" colors but I like them for this use case
                          1: theme.auto,
                        },
                      },
                    ]}
                    stats={[
                      {
                        stat: locationequipment1?.['AC6-AC7']?.ioValues?.air_temperature?.value,
                        label: locationequipment1?.['AC6-AC7']?.ioValues?.air_temperature?.ioValueType?.units,
                        precision: 1,
                      },
                      {
                        stat: locationequipment1?.['AC6-AC7']?.ioValues?.[IOValueKeys.hours]?.value,
                        label: locationequipment1?.['AC6-AC7']?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                      },
                    ]}
                  />
                </div>
              </div>
            </PumpsRowCustom>
            <PumpsRowCustom height="126px">
              <IRCompressor
                minWidth="206px"
                pipeTypeBelow={[false, false, false]}
                pipeTypeAbove={[false, true, false]}
                compressorData={locationequipment1.C9}
                location={props.locations?.locations?.[1]?.name}
                controlPositionTop="-33px"
                controlPositionLeft="2px"
                pipeColorAbove={theme.pipeColors.air}
                pipeColorBelow={theme.pipeColors.air}
                setIOValue={props.setIOValue}
                IOValueKeys={IOValueKeys}
                writeValues={[1, 1]}
                readValues={[1, 0]}
                stateKey={{
                  0: language.off,
                  1: language.on,
                }}
                stateColorKey={{
                  0: theme.secondary,
                  1: theme.onGreen,
                }}
                statuses={[
                  {
                    id: locationequipment1?.C9?.id,
                    label: 'Status',
                    status: locationequipment1?.C9?.textStatusKey,
                    statusKey: {
                      0: '',
                      1: 'Ready',
                      2: 'Unloaded',
                      3: 'Loaded',
                      4: 'Alarm',
                    },
                    statusColorKey: {
                      0: theme.auto,
                      1: theme.onGreen,
                      2: theme.autoStop,
                      3: theme.local,
                      4: theme.alarm,
                    },
                  },
                ]}
                stats={[
                  {
                    stat: locationequipment1?.C9?.ioValues?.[IOValueKeys.amps]?.value,
                    label: locationequipment1?.C9?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                  },
                  {
                    stat: locationequipment1?.C9?.ioValues?.[IOValueKeys.hours]?.value,
                    label: locationequipment1?.C9?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                  },
                ]}
                column1Info={{
                  statistics: [
                    locationequipment1?.C9?.ioValues?.oil_temperature?.value,
                    locationequipment1?.C9?.ioValues?.oil_pressure?.value,
                    locationequipment1?.C9?.ioValues?.inlet_valve?.value,
                  ],
                  subtitles: [
                    'Oil Temp',
                    'Oil Pressure',
                    'Inlet Valve',
                  ],
                  // ['Psi', 'Amp', 'Psi', '°F'], // May need these explicit units for better formatting
                  labels: [
                    locationequipment1?.C9?.ioValues?.oil_temperature?.ioValueType?.units,
                    locationequipment1?.C9?.ioValues?.oil_pressure?.ioValueType?.units,
                    locationequipment1?.C9?.ioValues?.inlet_valve?.ioValueType?.units,
                  ],
                  ids: [
                    locationequipment1?.C9?.ioValues?.oil_temperature?.id,
                    locationequipment1?.C9?.ioValues?.oil_pressure?.id,
                    locationequipment1?.C9?.ioValues?.inlet_valve?.id,
                  ],
                  precisions: [1, 0, 0],
                  mappings: [undefined, undefined, undefined],
                }}
                column2Info={{
                  statistics: [
                    locationequipment1?.C9?.ioValues?.[IOValueKeys.pressureSetpoint]?.value,
                    locationequipment1?.C9?.ioValues?.system_pressure?.value,
                    locationequipment1?.C9?.ioValues?.bypass_valve?.value,
                  ],
                  subtitles: [
                    'Psi Setpoint',
                    'System Pressure',
                    'Bypass Valve',
                  ],
                  // ['Psi', 'Amp', 'Psi', '°F'], // May need these explicit units for better formatting
                  labels: [
                    locationequipment1?.C9?.ioValues?.[IOValueKeys.pressureSetpoint]?.ioValueType?.units,
                    locationequipment1?.C9?.ioValues?.system_pressure?.ioValueType?.units,
                    locationequipment1?.C9?.ioValues?.bypass_valve?.ioValueType?.units,
                  ],
                  ids: [
                    locationequipment1?.C9?.ioValues?.[IOValueKeys.pressureSetpoint]?.id,
                    locationequipment1?.C9?.ioValues?.system_pressure?.id,
                    locationequipment1?.C9?.ioValues?.bypass_valve?.id,
                  ],
                  precisions: [0, 0, 0],
                  mappings: [undefined, undefined, undefined],
                }}
              />
              <div css={[styles.flexRow, css`min-width: 206px;`]}>
                <Pump
                  controlPositionTop="-40px"
                  controlPositionLeft="-15px"
                  percent
                  setIOValue={props.setIOValue}
                  pumpData={{ ...locationequipment1?.CP1 }}
                  otherData={locationequipment1?.Other}
                  stateColorKey={{
                    0: `${theme.secondary}`,
                    1: `${theme.onGreen}`,
                    2: `${theme.warning}`,
                    3: `${theme.alarm}`,
                  }}
                  readValues={[1, 0]}
                  writeValues={[1, 1]}
                  IOValueKeys={{
                    start: 'start',
                    stop: 'stop',
                    started: 'stop_start',
                    stopped: 'stop_start',
                  }}
                  alarms={[]}
                  stats={[
                    {
                      stat: locationequipment1?.CP1?.ioValues?.[IOValueKeys.amps]?.value,
                      label: locationequipment1?.CP1?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                    },
                    {
                      stat: locationequipment1?.CP1?.ioValues?.[IOValueKeys.hours]?.value,
                      label: locationequipment1?.CP1?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                    },
                  ]}
                />
              </div>
              <div css={[styles.flexRow, css`min-width: 206px;`]}>
                <Pump
                  controlPositionTop="-40px"
                  controlPositionLeft="-15px"
                  percent
                  setIOValue={props.setIOValue}
                  pumpData={{ ...locationequipment1?.CP2 }}
                  otherData={locationequipment1?.Other}
                  stateColorKey={{
                    0: `${theme.secondary}`,
                    1: `${theme.onGreen}`,
                    2: `${theme.warning}`,
                    3: `${theme.alarm}`,
                  }}
                  readValues={[1, 0]}
                  writeValues={[1, 1]}
                  IOValueKeys={{
                    start: 'start',
                    stop: 'stop',
                    started: 'stop_start',
                    stopped: 'stop_start',
                  }}
                  alarms={[]}
                  stats={[
                    {
                      stat: locationequipment1?.CP2?.ioValues?.[IOValueKeys.amps]?.value,
                      label: locationequipment1?.CP2?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                    },
                    {
                      stat: locationequipment1?.CP2?.ioValues?.[IOValueKeys.hours]?.value,
                      label: locationequipment1?.CP2?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                    },
                  ]}
                />
              </div>
              <div css={[styles.flexRow, css`min-width: 206px;`]}>
                <Pump
                  controlPositionTop="-40px"
                  controlPositionLeft="-15px"
                  percent
                  setIOValue={props.setIOValue}
                  pumpData={{ ...locationequipment1?.CP3 }}
                  otherData={locationequipment1?.Other}
                  stateColorKey={{
                    0: `${theme.secondary}`,
                    1: `${theme.onGreen}`,
                    2: `${theme.warning}`,
                    3: `${theme.alarm}`,
                  }}
                  readValues={[1, 0]}
                  writeValues={[1, 1]}
                  IOValueKeys={{
                    start: 'start',
                    stop: 'stop',
                    started: 'stop_start',
                    stopped: 'stop_start',
                  }}
                  alarms={[]}
                  stats={[
                    {
                      stat: locationequipment1?.CP3?.ioValues?.[IOValueKeys.amps]?.value,
                      label: locationequipment1?.CP3?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                    },
                    {
                      stat: locationequipment1?.CP3?.ioValues?.[IOValueKeys.hours]?.value,
                      label: locationequipment1?.CP3?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                    },
                  ]}
                />
              </div>
              <div css={[styles.flexRow, css`min-width: 356px; height: 100%; margin-left: 12px;`]}>
                <div css={css`width: 50%; height: 100%; margin-top: -14px;`}>
                  <AftercoolerFan
                    setIOValue={props.setIOValue}
                    imgStyle="height: 94px;"
                    controlPositionTop="-46px"
                    controlPositionLeft="-5px"
                    pipes={false}
                    fanData={{
                      ...locationequipment1?.['AC8-AC9'],
                      state: locationequipment1?.['AC8-AC9']?.status,
                      fanColor: locationequipment1?.['AC8-AC9']?.ioValues?.run_feedback?.value ? 'green' : 'red',
                    }}
                    statuses={[
                      {
                        id: locationequipment1?.['AC8-AC9'].ioValues?.fault?.id,
                        status: locationequipment1?.['AC8-AC9'].ioValues?.fault?.value,
                        statusKey: {
                          0: '',
                          1: 'Fault',
                        },
                        statusColorKey: {
                          0: theme.manual, // These aren't the "named" colors but I like them for this use case
                          1: theme.alarm,
                        },
                      },
                      {
                        id: locationequipment1?.['AC8-AC9'].ioValues?.auto?.id,
                        status: locationequipment1?.['AC8-AC9'].ioValues?.auto?.value,
                        statusKey: {
                          0: '',
                          1: 'Auto',
                        },
                        statusColorKey: {
                          0: '', // These aren't the "named" colors but I like them for this use case
                          1: theme.auto,
                        },
                      },
                    ]}
                    stats={[
                      {
                        stat: locationequipment1?.['AC8-AC9']?.ioValues?.air_temperature?.value,
                        label: locationequipment1?.['AC8-AC9']?.ioValues?.air_temperature?.ioValueType?.units,
                        precision: 1,
                      },
                      {
                        stat: locationequipment1?.['AC8-AC9']?.ioValues?.[IOValueKeys.hours]?.value,
                        label: locationequipment1?.['AC8-AC9']?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                      },
                    ]}
                  />
                </div>
                <div css={css`width: 50%; height: 100%; margin-top: -14px;`}>
                  <AftercoolerFan
                    setIOValue={props.setIOValue}
                    imgStyle="height: 94px;"
                    controlPositionTop="-46px"
                    controlPositionLeft="-5px"
                    pipes={false}
                    fanData={{
                      ...locationequipment1?.['AC10-AC11'],
                      state: locationequipment1?.['AC10-AC11']?.status,
                      fanColor: locationequipment1?.['AC10-AC11']?.ioValues?.run_feedback?.value ? 'green' : 'red',
                    }}
                    statuses={[
                      {
                        id: locationequipment1?.['AC10-AC11'].ioValues?.fault?.id,
                        status: locationequipment1?.['AC10-AC11'].ioValues?.fault?.value,
                        statusKey: {
                          0: '',
                          1: 'Fault',
                        },
                        statusColorKey: {
                          0: theme.manual, // These aren't the "named" colors but I like them for this use case
                          1: theme.alarm,
                        },
                      },
                      {
                        id: locationequipment1?.['AC10-AC11'].ioValues?.auto?.id,
                        status: locationequipment1?.['AC10-AC11'].ioValues?.auto?.value,
                        statusKey: {
                          0: '',
                          1: 'Auto',
                        },
                        statusColorKey: {
                          0: '', // These aren't the "named" colors but I like them for this use case
                          1: theme.auto,
                        },
                      },
                    ]}
                    stats={[
                      {
                        stat: locationequipment1?.['AC10-AC11']?.ioValues?.air_temperature?.value,
                        label: locationequipment1?.['AC10-AC11']?.ioValues?.air_temperature?.ioValueType?.units,
                        precision: 1,
                      },
                      {
                        stat: locationequipment1?.['AC10-AC11']?.ioValues?.[IOValueKeys.hours]?.value,
                        label: locationequipment1?.['AC10-AC11']?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                      },
                    ]}
                  />
                </div>
              </div>
            </PumpsRowCustom>
          </LabeledContainer>
        </BasicRow>
      </div>
    </div>
  );
}

const stylesFromTheme = (theme, props) => {
  return {
    fullscreen: css`
      min-width: calc(100vw - ${props.navbarClosed ? '16px' : '160px'});
      max-width: calc(100vw - ${props.navbarClosed ? '16px' : '160px'});
      min-height: 100vh;
      max-height: 100vh;
      overflow: scroll;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        min-width: calc(100vw - 16px);
        max-width: calc(100vw - 16px);
        margin-left: 16px;
      }
      transition: width ease 0.4s, min-width ease 0.4s, max-width ease 0.4s; 
    `,
    hiddenMobile: css`
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        display: none;
      }
    `,
    flexRow: css`
      height: 96px;
      display: flex;
      flex: 1;
      flex-direction: row;
      min-width: 175px;
      position: relative;
    `,
    floatPump: css`
      position: absolute;
      top: 35px;
      left: 5px;
    `,
    flexCol: css`
      display: flex;
      flex: 1;
      flex-direction: column;
      height: 100%;
    `,
    pumpWrapper: css`
      display: flex;
      flex: 3;
      flex-direction: row;
      height: 100%;
      position: relative;
    `,
    redBox: css`
      border: 2px dashed red;
      z-index: 3;
      background-color: rgba(0,0,0,0.5);
      position: absolute;
      top: 0;
      left: 0;
      width: 133%;
      height: 66%;
      margin: 30px 0px 0px -9px;
    `,
    hideScrollbar: css`
      overflow-y: scroll;
      ::-webkit-scrollbar { /* WebKit */
        width: 0;
        height: 0;
        display: none;
      }
      scrollbar-width: none; /* Firefox */
    `,
    navClosedPadding: css`
      padding-left: 16px;
    `,
    navOpenPadding: css`
      padding-left: 160px;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        padding-left: 16px;
      }
    `,
    loadingContainer: css`
      position: absolute;
      z-index: 4;
      top: 0px;
      left 0px;
      width: 100%;
      height: 100%;
      background-color: grey;
      transition: padding-left ease 0.4s, opacity ease 2.5s;
    `,
  };
};

SnowflakeScreen.propTypes = {
  navbarClosed: PropTypes.bool.isRequired,
  fetchPLCs: PropTypes.func.isRequired,
  fetchLocation: PropTypes.func.isRequired,
  setIOValue: PropTypes.func.isRequired,
  locations: PropTypes.shape({
    locations: PropTypes.shape({}),
  }),
  plcs: PropTypes.shape({
    plcs: PropTypes.shape({}),
  }),
};

SnowflakeScreen.defaultProps = {
  locations: null,
  plcs: null,
};

const mapStateToProps = (state) => ({
  navbarClosed: state.nav.navbarClosed,
  locations: state.locations,
  plcs: state.plcs,
});

export default connect(mapStateToProps, { fetchLocation, setIOValue, fetchPLCs })(SnowflakeScreen);
