import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTheme } from 'emotion-theming';
import { css } from '@emotion/core';

import {
  Pump,
  Valve,
  Pipe,
  PipePlaceholder,
  ValveConnect,
  Pond,
  MiddlePipeL,
  WaterTank,
  AirTank,
  Compressor,
  TriplePipeFork,
  DoublePipeFork,
  PipeFork,
  ConnectionIndicator,
} from 'isno/lib/components/equipment';

import {
  BasicRow,
  InfoControlRow,
  PumpsRowMedium,
  PumpsRow,
  PumpsRowSmall,
  PumpsRowExtraSmall,
  LabeledContainer,
  PumpsRowCustom,
} from 'isno/lib/components/layout';

import {
  Weather,
  Info,
  MultiInfo,
  // Totals,
  Statistic,
  MobileStatTable,
  PLCStateRouter,
  PLCColorRouter,
  LoadingIndicator,
} from 'isno/lib/components/displays';

import {
  AutoValveControl,
  VFDPumpControl,
  InfoControl,
  ToggleControl,
  AlarmReset,
  MultiSetpointControl,
  SequenceControl,
} from 'isno/lib/components/controls';

import { startCase } from 'lodash';

import PLC from 'isno/lib/static/images/PLC.png';
import selectorToggle from 'isno/lib/static/images/selectorToggle.svg';
import selectorToggleRotate from 'isno/lib/static/images/selectorToggleRotate.svg';
import selectorToggleRotateRed from 'isno/lib/static/images/selectorToggleRotateRed.svg';
import { LanguageContext } from 'isno/lib/languages/LanguageContext';
import { fetchLocation, setIOValue, fetchPLCs } from '../actions';

// Should maintain one large key that I pass in to each component
// and can configure from pumphouse to pumphouse
const IOValueKeys = {
  // Building Data
  buildingTemp: 'building_temperature',
  // Water Flow Source
  cityTankMode: 'city_tank_mode',
  // Water Line Data
  waterFlowRate: 'water_flow',
  suctionWaterPressure: 'suction_water_pressure',
  line3WaterFlow: 'line_3_water_flow',
  line2WaterFlow: 'line_2_water_flow',
  // Air Line Data
  line2AirFlow: 'line_2_air_flow',
  line2AirTemp: 'line_2_air_temperature',
  line3AirFlow: 'line_3_air_flow',
  line3AirTemp: 'line_3_air_temperature',
  dischargeAirPressure: 'discharge_air_pressure',
  // Pump Data
  speed: 'speed',
  dischargePressure: 'discharge_water_pressure',
  dischargeTemperature: 'discharge_water_temperature',
  amps: 'current',
  hours: 'run_time',
  // Pump Alarms
  warning: 'warning',
  fault: 'fault',
  lowAmps: 'low_amps',
  masterAlarm: 'master_alarm',
  lowSuctionPressure: 'low_suction_pressure',
  emergencyStop: 'emergency_stop',
  // Valve Control
  openClose: 'open_close',
  // Valve Alarms
  // v1OpeningError: 'V1_opening_error',
  // v1ClosingError: 'V1_closing_error',
};

function RiverScreen(props) {
  const theme = useTheme();
  const { language } = useContext(LanguageContext);
  const { navbarClosed } = props;
  const styles = stylesFromTheme(theme, { navbarClosed });

  let oneSecondIntervalId;

  const oneSecondInterval = () => {
    oneSecondIntervalId = setTimeout(async () => {
      const promises = [
        props.fetchLocation(3),
        props.fetchLocation(4),
        props.fetchPLCs(),
      ];
      await Promise.all(promises);
      oneSecondIntervalId = setTimeout(oneSecondInterval, 1000);
    }, 1000);
  };

  useEffect(() => {
    // Async function to asynchronously request all of our data but then wait for
    // all of the responses before sending the next request. We use
    // this to make sure we aren't flooding our server with requests.
    props.fetchLocation(3);
    props.fetchLocation(4);
    props.fetchPLCs();
    oneSecondInterval();
    return () => {
      clearTimeout(oneSecondIntervalId);
    };
  }, []);

  const locationequipment3 = props.locations?.locations?.[3]?.equipment;
  const locationequipment4 = props.locations?.locations?.[4]?.equipment;
  if (!props.locations?.locations?.[3] || !props.locations?.locations?.[4]) {
    return (
      <div
        css={[
          styles.loadingContainer,
          props.navbarClosed ? styles.navClosedPadding : styles.navOpenPadding,
        ]}
      >
        <div css={css`position: relative; width: 100%; height: 100%;`}>
          <LoadingIndicator visible zIndex={4} />
        </div>
      </div>
    );
  }

  // TODO: fix style names... maybe use styled components
  return (
    <div css={[styles.fullscreen, styles.hideScrollbar]}>
      <div css={css`min-height: 0px;`}>
        <BasicRow styles="min-width: fit-content; min-height: fit-content; display: flex; flex-direction: row; flex-wrap: nowrap;">
          <LabeledContainer styles2="display: flex; justify-content: space-between; flex-direction: column; height: calc(100% - 42px);" height="fit-content" flex="3" border label="River Pumphouse 1">
            <InfoControlRow>
              <VFDPumpControl
                title="P3 PID Control"
                pumpData={{ id: `controlValve_${locationequipment4?.['P3 PID']?.id}` }}
                pidData={locationequipment4?.['P3 PID']}
                setIOValue={props.setIOValue}
                max="1500"
                IOValueKeys={{
                  autoMode: 'auto_manual',
                  manualMode: 'auto_manual',
                  autoManual: 'auto_manual',
                  autoSetpoint: 'auto_setpoint',
                  manualSetpoint: 'manual_setpoint',
                  pGainSetpoint: 'pgain_setpoint',
                  iGainSetpoint: 'igain_setpoint',
                }}
                readValues={[0, 1]}
                writeValues={[0, 1]}
                settings
              />
              <SequenceControl
                title="Sequencer"
                pumpsHeight="414px"
                pumpsWidth="576px"
                setIOValue={props.setIOValue}
                IOValueKeys={{
                  enable: 'sequencer_disable_enable',
                  disable: 'sequencer_disable_enable',
                  link: 'p3_p8_link',
                }}
                linkVFD
                leadVFD={locationequipment3?.Sequencer?.ioValues?.sequence_lead_vfd}
                leadVFDId={locationequipment3?.Sequencer?.ioValues?.sequence_lead_vfd?.value ? locationequipment3?.P8?.id : locationequipment4?.P3?.id}
                leadVFDlabel1={locationequipment4?.P3?.name}
                leadVFDlabel2={locationequipment3?.P8?.name}
                sequencerData={locationequipment3?.Sequencer}
                id={locationequipment3?.Sequencer?.id}
                readValues={[1, 0]}
                writeValues={[1, 0]}
                pumps={[
                  locationequipment4?.P1,
                  locationequipment4?.P2,
                  locationequipment4?.P3,
                  locationequipment4?.P4,
                  locationequipment3?.P5,
                  locationequipment3?.P6,
                  locationequipment3?.P7,
                  locationequipment3?.P8,
                ]}
                disableEnable={[
                  locationequipment3?.Sequencer?.ioValues?.p1_disable_enable,
                  locationequipment3?.Sequencer?.ioValues?.p2_disable_enable,
                  locationequipment3?.Sequencer?.ioValues?.p3_disable_enable,
                  locationequipment3?.Sequencer?.ioValues?.p4_disable_enable,
                  locationequipment3?.Sequencer?.ioValues?.p5_disable_enable,
                  locationequipment3?.Sequencer?.ioValues?.p6_disable_enable,
                  locationequipment3?.Sequencer?.ioValues?.p7_disable_enable,
                  locationequipment3?.Sequencer?.ioValues?.p8_disable_enable,
                ]}
                setpoints={[
                  locationequipment3?.Sequencer?.ioValues?.p1_sequence_order_setpoint,
                  locationequipment3?.Sequencer?.ioValues?.p2_sequence_order_setpoint,
                  locationequipment3?.Sequencer?.ioValues?.p3_sequence_order_setpoint,
                  locationequipment3?.Sequencer?.ioValues?.p4_sequence_order_setpoint,
                  locationequipment3?.Sequencer?.ioValues?.p5_sequence_order_setpoint,
                  locationequipment3?.Sequencer?.ioValues?.p6_sequence_order_setpoint,
                  locationequipment3?.Sequencer?.ioValues?.p7_sequence_order_setpoint,
                  locationequipment3?.Sequencer?.ioValues?.p8_sequence_order_setpoint,
                ]}
                startParam={[
                  locationequipment3?.Sequencer?.ioValues?.start_fast_offset_setpoint,
                  locationequipment3?.Sequencer?.ioValues?.start_fast_timer_setpoint,
                  locationequipment3?.Sequencer?.ioValues?.start_slow_offset_setpoint,
                  locationequipment3?.Sequencer?.ioValues?.start_slow_timer_setpoint,
                ]}
                startValues={[
                  locationequipment3?.Sequencer?.ioValues?.start_fast_offset,
                  locationequipment3?.Sequencer?.ioValues?.start_fast_timer,
                  locationequipment3?.Sequencer?.ioValues?.start_slow_offset,
                  locationequipment3?.Sequencer?.ioValues?.start_slow_timer,
                ]}
                startLabels={[
                  'Fast Offset',
                  'Fast Timer',
                  'Slow Offset',
                  'Slow Timer',
                ]}
                stopParam={[
                  locationequipment3?.Sequencer?.ioValues?.stop_fast_offset_setpoint,
                  locationequipment3?.Sequencer?.ioValues?.stop_fast_seal_setpoint,
                  locationequipment3?.Sequencer?.ioValues?.stop_fast_speed_setpoint,
                  locationequipment3?.Sequencer?.ioValues?.stop_fast_timer_setpoint,
                  locationequipment3?.Sequencer?.ioValues?.stop_slow_offset_setpoint,
                  locationequipment3?.Sequencer?.ioValues?.stop_slow_seal_setpoint,
                  locationequipment3?.Sequencer?.ioValues?.stop_slow_speed_setpoint,
                  locationequipment3?.Sequencer?.ioValues?.stop_slow_timer_setpoint,
                ]}
                stopValues={[
                  locationequipment3?.Sequencer?.ioValues?.stop_fast_offset,
                  null,
                  null,
                  locationequipment3?.Sequencer?.ioValues?.stop_fast_timer,
                  locationequipment3?.Sequencer?.ioValues?.stop_slow_offset,
                  null,
                  null,
                  locationequipment3?.Sequencer?.ioValues?.stop_slow_timer,
                ]}
                stopLabels={[
                  'Fast Offset',
                  'Fast Seal',
                  'Fast Speed',
                  'Fast Timer',
                  'Slow Offset',
                  'Slow Seal',
                  'Slow Speed',
                  'Slow Timer',
                ]}
              />
              <Info
                title="Room Temp."
                statistic={locationequipment4?.Other?.ioValues?.room_temperature?.value}
                label={locationequipment4?.Other?.ioValues?.room_temperature?.ioValueType?.units}
                precision={1}
                alertText={locationequipment4?.Other?.ioValues?.low_room_temperature?.value ? 'Low Room Temp.' : null}
                alertTop="69px"
                alertLeft="-8px"
              />
              <Info
                img={PLC}
                title="PLC Status"
                statistic={PLCStateRouter(props.plcs?.plcs?.[1])} // plcId 1: River_Pumphouse_1
                label=""
                color={PLCColorRouter(props.plcs?.plcs?.[1], theme)}
              />
              <AlarmReset
                title="System Reset"
                buttonText="Reset"
                alarmData={locationequipment4?.Other}
                setIOValue={props.setIOValue}
                alarmKeys={{
                  masterAlarm: 'master_alarm',
                  masterAlarmReset: 'master_alarm_reset',
                }}
                resetWriteValue={1}
              />
            </InfoControlRow>
            <div css={css`display: flex; flex-direction: column; height: fit-content;`}>
              <PumpsRowCustom height="30px" />
              <PumpsRowSmall>
                <PipePlaceholder hidden />
                <div css={styles.flexRow}>
                  <PipePlaceholder pipeAbove="right" minWidth="45%" />
                  <div css={css`margin-top: -3px;`}>
                    <Statistic
                      label={locationequipment4?.Other?.ioValues?.discharge_water_pressure?.ioValueType?.units}
                      border
                      statistic={locationequipment4?.Other?.ioValues?.discharge_water_pressure?.value}
                    />
                  </div>
                  <PipePlaceholder horizontalAbove capAfter minWidth="45%" />
                  <div css={[css`position: absolute; top: 22px; left: 112px; @media only screen and (max-width: ${theme.mobileBreakpoint}px) {top: -2px; left: 38px;}`]}>
                    To Barker
                  </div>
                </div>
                <PipePlaceholder hidden />
              </PumpsRowSmall>
              <PumpsRowMedium>
                <Pump
                  controlPositionTop="-40px"
                  controlPositionLeft="-21px"
                  componentMinWidth="140px"
                  pipeAbove="right"
                  percent
                  setIOValue={props.setIOValue}
                  pumpData={locationequipment4?.P1}
                  otherData={locationequipment4?.Other}
                  stateColorKey={{
                    0: `${theme.secondary}`,
                    1: `${theme.onGreen}`,
                    2: `${theme.warning}`,
                    3: `${theme.alarm}`,
                  }}
                  IOValueKeys={{
                    start: 'start',
                    stop: 'stop',
                    started: 'stop_start',
                    stopped: 'stop_start',
                  }}
                  alarms={[
                    locationequipment4?.P1?.ioValues?.low_amps,
                    locationequipment4?.P1?.ioValues?.fault,
                  ]}
                  stats={[
                    {
                      stat: locationequipment4?.P1?.ioValues?.[IOValueKeys.amps]?.value,
                      label: locationequipment4?.P1?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                    },
                    {
                      stat: locationequipment4?.P1?.ioValues?.[IOValueKeys.hours]?.value,
                      label: locationequipment4?.P1?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                    },
                  ]}
                />
                <Pump
                  controlPositionTop="-40px"
                  controlPositionLeft="-21px"
                  componentMinWidth="140px"
                  pipeAbove="middle"
                  percent
                  setIOValue={props.setIOValue}
                  pumpData={locationequipment4?.P2}
                  otherData={locationequipment4?.Other}
                  stateColorKey={{
                    0: `${theme.secondary}`,
                    1: `${theme.onGreen}`,
                    2: `${theme.warning}`,
                    3: `${theme.alarm}`,
                  }}
                  IOValueKeys={{
                    start: 'start',
                    stop: 'stop',
                    started: 'stop_start',
                    stopped: 'stop_start',
                  }}
                  alarms={[
                    locationequipment4?.P2?.ioValues?.low_amps,
                    locationequipment4?.P2?.ioValues?.fault,
                  ]}
                  stats={[
                    {
                      stat: locationequipment4?.P2?.ioValues?.[IOValueKeys.amps]?.value,
                      label: locationequipment4?.P2?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                    },
                    {
                      stat: locationequipment4?.P2?.ioValues?.[IOValueKeys.hours]?.value,
                      label: locationequipment4?.P2?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                    },
                  ]}
                />
                <Pump
                  controlPositionTop="-40px"
                  controlPositionLeft="-21px"
                  componentMinWidth="140px"
                  pipeAbove="middle"
                  percent
                  setIOValue={props.setIOValue}
                  pumpData={locationequipment4?.P3}
                  otherData={locationequipment4?.Other}
                  stateColorKey={{
                    0: `${theme.secondary}`,
                    1: `${theme.onGreen}`,
                    2: `${theme.warning}`,
                    3: `${theme.alarm}`,
                  }}
                  IOValueKeys={{
                    start: 'start',
                    stop: 'stop',
                    started: 'stop_start',
                    stopped: 'stop_start',
                    speed: 'speed',
                  }}
                  alarms={[
                    locationequipment4?.P3?.ioValues?.low_amps,
                    locationequipment4?.P3?.ioValues?.fault,
                    locationequipment4?.P3?.ioValues?.high_seal_temperature,
                    locationequipment4?.P3?.ioValues?.high_seal_temperature_switch,
                  ]}
                  stats={[
                    {
                      stat: locationequipment4?.P3?.ioValues?.[IOValueKeys.amps]?.value,
                      label: locationequipment4?.P3?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                    },
                    {
                      stat: locationequipment4?.P3?.ioValues?.[IOValueKeys.hours]?.value,
                      label: locationequipment4?.P3?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                    },
                    {
                      stat: locationequipment4?.P3?.ioValues?.seal_temperature?.value,
                      label: locationequipment4?.P3?.ioValues?.seal_temperature?.ioValueType?.units,
                      precision: 1,
                    },
                  ]}
                />
                <Pump
                  controlPositionTop="-40px"
                  controlPositionLeft="-21px"
                  componentMinWidth="140px"
                  pipeAbove="left"
                  percent
                  setIOValue={props.setIOValue}
                  pumpData={locationequipment4?.P4}
                  otherData={locationequipment4?.Other}
                  stateColorKey={{
                    0: `${theme.secondary}`,
                    1: `${theme.onGreen}`,
                    2: `${theme.warning}`,
                    3: `${theme.alarm}`,
                  }}
                  IOValueKeys={{
                    start: 'start',
                    stop: 'stop',
                    started: 'stop_start',
                    stopped: 'stop_start',
                  }}
                  alarms={[
                    locationequipment4?.P4?.ioValues?.low_amps,
                    locationequipment4?.P4?.ioValues?.fault,
                  ]}
                  stats={[
                    {
                      stat: locationequipment4?.P4?.ioValues?.[IOValueKeys.amps]?.value,
                      label: locationequipment4?.P4?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                    },
                    {
                      stat: locationequipment4?.P4?.ioValues?.[IOValueKeys.hours]?.value,
                      label: locationequipment4?.P4?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                    },
                  ]}
                />
              </PumpsRowMedium>
              <PumpsRowExtraSmall>
                <Pond
                  color={theme.pipeColors.water}
                  id={`Lake_Info_${locationequipment4?.id}`}
                >
                  <Info
                    title="Pond Level"
                    precision={1}
                    statistic={locationequipment4?.Other?.ioValues?.pond_level?.value}
                    label={locationequipment4?.Other?.ioValues?.pond_level?.ioValueType?.units}
                    alertText={(() => {
                      if (locationequipment4?.Other?.ioValues?.low_water_level?.value) {
                        return 'Low Pit Level';
                        // } else if (locationequipment1?.Other?.ioValues?.low_pit_level_switch?.value) {
                        //   return 'Low Pit Level';
                      } else {
                        return null;
                      }
                    })()}
                    alertTop="-23px"
                    alertLeft={locationequipment4?.Other?.ioValues?.low_water_level?.value ? '-50px' : '-15px'}
                  />
                </Pond>
              </PumpsRowExtraSmall>
            </div>
          </LabeledContainer>
          <LabeledContainer height="fit-content" flex="3" border label="River Pumphouse 2">
            <InfoControlRow>
              <VFDPumpControl
                title="P8 PID Control"
                pumpData={{ id: `controlValve_${locationequipment3?.['P8 PID']?.id}` }}
                pidData={locationequipment3?.['P8 PID']}
                setIOValue={props.setIOValue}
                max="1500"
                IOValueKeys={{
                  autoMode: 'auto_manual',
                  manualMode: 'auto_manual',
                  autoManual: 'auto_manual',
                  autoSetpoint: 'auto_setpoint',
                  manualSetpoint: 'manual_setpoint',
                  pGainSetpoint: 'pgain_setpoint',
                  iGainSetpoint: 'igain_setpoint',
                }}
                readValues={[0, 1]}
                writeValues={[0, 1]}
                settings
              />
              <MultiSetpointControl
                title="CV1 PID Control"
                pidData={locationequipment3?.['CV1 PID']}
                IOValueIDs={[
                  `${locationequipment3?.['CV1 PID']?.ioValues?.back_auto_setpoint?.id}`,
                  `${locationequipment3?.['CV1 PID']?.ioValues?.hill_auto_setpoint?.id}`,
                  `${locationequipment3?.['CV1 PID']?.ioValues?.manual_setpoint?.id}`,
                ]}
                statistics={[
                  `${locationequipment3?.['CV1 PID']?.ioValues?.back_auto_setpoint?.value}`,
                  `${locationequipment3?.['CV1 PID']?.ioValues?.hill_auto_setpoint?.value}`,
                  `${locationequipment3?.['CV1 PID']?.ioValues?.manual_setpoint?.value}`,
                ]}
                labels={['Back Auto SP:', 'Hill Auto SP:', 'Manual Setpoint:']}
                units={[
                  locationequipment3?.['CV1 PID']?.ioValues?.back_auto_setpoint?.ioValueType?.units,
                  locationequipment3?.['CV1 PID']?.ioValues?.hill_auto_setpoint?.ioValueType?.units,
                  locationequipment3?.['CV1 PID']?.ioValues?.manual_setpoint?.ioValueType?.units,
                ]}
                setIOValue={props.setIOValue}
                IOValueKeys={{
                  autoManual: 'auto_manual',
                  autoMode: 'auto_manual',
                  manualMode: 'auto_manual',
                  pressureMode: 'hill_back',
                }}
                readValues={[0, 1]}
                writeValues={[0, 1]}
                buttons
              />
              <Info
                img={locationequipment3?.Other?.ioValues?.panel_auto_switch?.value ? selectorToggleRotate : locationequipment3?.Other?.ioValues?.panel_hand_switch?.value ? selectorToggleRotateRed : selectorToggle}
                title="Panel Switch"
                statistic={locationequipment3?.Other?.ioValues?.panel_auto_switch?.value ? 'Auto' : locationequipment3?.Other?.ioValues?.panel_hand_switch?.value ? 'Hand' : 'Off'}
                label=""
                color={locationequipment3?.Other?.ioValues?.panel_auto_switch?.value ? theme.onGreen : locationequipment3?.Other?.ioValues?.panel_hand_switch?.value ? theme.alarm : ''}
                imgStyle="margin-top: 8px; max-height: 35px; margin-right: -7px;"
              />
              <MultiInfo
                title="Pump Panels"
                subtitles={['P5:', 'P6:', 'P7:', 'P8:']}
                statistics={[
                  locationequipment3?.P5?.ioValues?.panel_on_off?.value ? 'On' : 'Off',
                  locationequipment3?.P6?.ioValues?.panel_on_off?.value ? 'On' : 'Off',
                  locationequipment3?.P7?.ioValues?.panel_on_off?.value ? 'On' : 'Off',
                  locationequipment3?.P8?.ioValues?.panel_on_off?.value ? 'On' : 'Off',
                ]}
                ids={[
                  locationequipment3?.P5?.ioValues?.panel_on_off?.id,
                  locationequipment3?.P6?.ioValues?.panel_on_off?.id,
                  locationequipment3?.P7?.ioValues?.panel_on_off?.id,
                  locationequipment3?.P8?.ioValues?.panel_on_off?.id,
                ]}
                colors={[
                  locationequipment3?.P5?.ioValues?.panel_on_off?.value ? theme.onGreen : theme.secondary,
                  locationequipment3?.P6?.ioValues?.panel_on_off?.value ? theme.onGreen : theme.secondary,
                  locationequipment3?.P7?.ioValues?.panel_on_off?.value ? theme.onGreen : theme.secondary,
                  locationequipment3?.P8?.ioValues?.panel_on_off?.value ? theme.onGreen : theme.secondary,
                ]}
                labels={[]}
                styles="flex-direction: row; flex-wrap: wrap; justify-content: flex-start; width: 170px;"
              />
              <ToggleControl
                title="Motor Cooler"
                ioData={locationequipment3?.['Motor Cooler']}
                IOValueKeys={{
                  start: 'start',
                  stop: 'stop',
                  started: 'stop_start',
                  stopped: 'stop_start',
                }}
                setIOValue={props.setIOValue}
                button1Text="Enable"
                button2Text="Disable"
                readValues={[1, 0]}
                writeValues={[1, 1]}
                dot
                buttonWidth="56px"
              />
              <Info
                title="Instrument Air"
                statistic={locationequipment3?.IA?.ioValues?.disabled_enabled?.value ? 'Enabled' : 'Disabled'}
                color={locationequipment3?.IA?.ioValues?.disabled_enabled?.value ? theme.onGreen : theme.alarm}
                label=""
              />
              <Info
                title="Room Temp."
                statistic={locationequipment3?.Other?.ioValues?.room_temperature?.value}
                label={locationequipment3?.Other?.ioValues?.room_temperature?.ioValueType?.units}
                precision={1}
                alertText={locationequipment3?.Other?.ioValues?.low_room_temperature?.value ? 'Low Room Temp.' : null}
                alertTop="69px"
                alertLeft="-8px"
              />
              <Info
                img={PLC}
                title="PLC Status"
                statistic={PLCStateRouter(props.plcs?.plcs?.[2])} // plcId 2: River_Pumphouse_2
                label=""
                color={PLCColorRouter(props.plcs?.plcs?.[2], theme)}
              />
              <AlarmReset
                title="System Reset"
                buttonText="Reset"
                alarmData={locationequipment3?.Other}
                setIOValue={props.setIOValue}
                alarmKeys={{
                  masterAlarm: 'master_alarm',
                  masterAlarmReset: 'master_alarm_reset',
                }}
                resetWriteValue={1}
              />
            </InfoControlRow>
            <PumpsRowCustom height="30px" />
            <PumpsRowSmall>
              <PipePlaceholder hidden />
              <div css={styles.flexRow}>
                <PipePlaceholder pipeAbove="right" minWidth="30%" />
                <div css={css`margin-top: -3px;`}>
                  <Statistic
                    label={locationequipment3?.Other?.ioValues?.back_water_pressure?.ioValueType?.units}
                    border
                    statistic={locationequipment3?.Other?.ioValues?.back_water_pressure?.value}
                  />
                </div>
                <ValveConnect
                  showOnMobile
                  horizontal="above"
                  nameLeft="8px"
                  nameTop="18px"
                  percentTop="51px"
                  percentLeft="3px"
                  minWidth="9%"
                  percent
                  valveData={{
                    ...locationequipment3?.CV1,
                    state: locationequipment3?.CV1?.ioValues?.position?.value,
                    id: `controlValve_${locationequipment3?.CV1?.id}`,
                  }}
                  setIOValue={props.setIOValue}
                  readValues={[100, 0]}
                  valveStateKeys={{
                    0: '0',
                    100: '100',
                  }}
                  valveStateColorKeys={{
                    0: theme.offRed,
                    100: theme.onGreen,
                  }}
                  IOValueKeys={{
                    position: 'position',
                  }}
                  greenImageKey="Opened"
                  binary={false}
                />
              </div>
              <div css={styles.flexRow}>
                <div css={[css`position: absolute; top: 22px; left: 43px;`]}>
                  To Barker
                </div>
                <div css={css`margin-top: -3px;`}>
                  <Statistic
                    label={locationequipment3?.Other?.ioValues?.discharge_water_pressure?.ioValueType?.units}
                    border
                    statistic={locationequipment3?.Other?.ioValues?.discharge_water_pressure?.value}
                  />
                </div>
                <PipePlaceholder horizontalAbove capAfter minWidth="50%" />
                <PipePlaceholder hidden minWidth="50%" />
              </div>
            </PumpsRowSmall>
            <PumpsRowMedium>
              <Pump
                controlPositionTop="-40px"
                controlPositionLeft="-21px"
                componentMinWidth="140px"
                pipeAbove="right"
                percent
                setIOValue={props.setIOValue}
                pumpData={locationequipment3?.P5}
                otherData={locationequipment3?.Other}
                stateColorKey={{
                  0: `${theme.secondary}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                IOValueKeys={{
                  start: 'start',
                  stop: 'stop',
                  started: 'stop_start',
                  stopped: 'stop_start',
                }}
                alarms={[
                  locationequipment3?.P5?.ioValues?.low_amps,
                  locationequipment3?.P5?.ioValues?.flow_switch,
                ]}
                stats={[
                  {
                    stat: locationequipment3?.P5?.ioValues?.[IOValueKeys.amps]?.value,
                    label: locationequipment3?.P5?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                  },
                  {
                    stat: locationequipment3?.P5?.ioValues?.[IOValueKeys.hours]?.value,
                    label: locationequipment3?.P6?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                  },
                ]}
              />
              <Pump
                controlPositionTop="-40px"
                controlPositionLeft="-21px"
                componentMinWidth="140px"
                pipeAbove="middle"
                percent
                setIOValue={props.setIOValue}
                pumpData={locationequipment3?.P6}
                otherData={locationequipment3?.Other}
                stateColorKey={{
                  0: `${theme.secondary}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                IOValueKeys={{
                  start: 'start',
                  stop: 'stop',
                  started: 'stop_start',
                  stopped: 'stop_start',
                }}
                alarms={[
                  locationequipment3?.P6?.ioValues?.low_amps,
                  locationequipment3?.P6?.ioValues?.flow_switch,
                ]}
                stats={[
                  {
                    stat: locationequipment3?.P6?.ioValues?.[IOValueKeys.amps]?.value,
                    label: locationequipment3?.P6?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                  },
                  {
                    stat: locationequipment3?.P6?.ioValues?.[IOValueKeys.hours]?.value,
                    label: locationequipment3?.P6?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                  },
                ]}
              />
              <Pump
                controlPositionTop="-40px"
                controlPositionLeft="-21px"
                componentMinWidth="140px"
                pipeAbove="middle"
                percent
                setIOValue={props.setIOValue}
                pumpData={locationequipment3?.P7}
                otherData={locationequipment3?.Other}
                stateColorKey={{
                  0: `${theme.secondary}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                IOValueKeys={{
                  start: 'start',
                  stop: 'stop',
                  started: 'stop_start',
                  stopped: 'stop_start',
                  speed: 'speed',
                }}
                alarms={[
                  locationequipment3?.P7?.ioValues?.low_amps,
                  locationequipment3?.P7?.ioValues?.fault,
                  locationequipment3?.P7?.ioValues?.flow_switch,
                ]}
                stats={[
                  {
                    stat: locationequipment3?.P7?.ioValues?.[IOValueKeys.amps]?.value,
                    label: locationequipment3?.P7?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                  },
                  {
                    stat: locationequipment3?.P7?.ioValues?.[IOValueKeys.hours]?.value,
                    label: locationequipment3?.P7?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                  },
                ]}
              />
              <Pump
                controlPositionTop="-40px"
                controlPositionLeft="-21px"
                componentMinWidth="140px"
                pipeAbove="left"
                percent
                setIOValue={props.setIOValue}
                pumpData={locationequipment3?.P8}
                otherData={locationequipment3?.Other}
                stateColorKey={{
                  0: `${theme.secondary}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                IOValueKeys={{
                  start: 'start',
                  stop: 'stop',
                  started: 'stop_start',
                  stopped: 'stop_start',
                  speed: 'speed',
                }}
                alarms={[
                  locationequipment3?.P8?.ioValues?.low_amps,
                  locationequipment3?.P8?.ioValues?.flow_switch,
                  locationequipment3?.P8?.ioValues?.high_seal_temperature,
                ]}
                stats={[
                  {
                    stat: locationequipment3?.P8?.ioValues?.[IOValueKeys.amps]?.value,
                    label: locationequipment3?.P8?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                  },
                  {
                    stat: locationequipment3?.P8?.ioValues?.[IOValueKeys.hours]?.value,
                    label: locationequipment3?.P8?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                  },
                  {
                    stat: locationequipment3?.P8?.ioValues?.seal_temperature?.value,
                    label: locationequipment3?.P8?.ioValues?.seal_temperature?.ioValueType?.units,
                  },
                ]}
              />
            </PumpsRowMedium>
            <PumpsRowExtraSmall>
              <Pond
                color={theme.pipeColors.water}
                id={`Lake_Info_${locationequipment3?.id}`}
              >
                <Info
                  title="Pond Level"
                  precision={1}
                  statistic={locationequipment3?.Other?.ioValues?.pond_level?.value}
                  label={locationequipment3?.Other?.ioValues?.pond_level?.ioValueType?.units}
                  alertText={(() => {
                    if (locationequipment3?.Other?.ioValues?.low_water_level?.value) {
                      return 'Low Pit Level';
                    } else if (locationequipment3?.Other?.ioValues?.low_water_level_switch?.value) {
                      return 'Low Pit Level';
                    } else {
                      return null;
                    }
                  })()}
                  alertTop="-23px"
                  alertLeft={locationequipment3?.Other?.ioValues?.low_water_level?.value ? '-50px' : '-15px'}
                />
              </Pond>
            </PumpsRowExtraSmall>
          </LabeledContainer>
        </BasicRow>
      </div>
    </div>
  );
}

const stylesFromTheme = (theme, props) => {
  return {
    fullscreen: css`
      min-width: calc(100vw - ${props.navbarClosed ? '16px' : '160px'});
      max-width: calc(100vw - ${props.navbarClosed ? '16px' : '160px'});
      min-height: 100vh;
      max-height: 100vh;
      overflow: scroll;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        min-width: calc(100vw - 16px);
        max-width: calc(100vw - 16px);
        margin-left: 16px;
      }
      transition: width ease 0.4s, min-width ease 0.4s, max-width ease 0.4s; 
    `,
    hiddenMobile: css`
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        display: none;
      }
    `,
    flexRow: css`
      display: flex;
      flex: 1;
      flex-direction: row;
      height: 100%;
      min-width: 175px;
      position: relative;
    `,
    flexCol: css`
      display: flex;
      flex: 1;
      flex-direction: column;
      height: 100%;
    `,
    pumpWrapper: css`
      display: flex;
      flex: 3;
      flex-direction: row;
      height: 100%;
      position: relative;
    `,
    redBox: css`
      border: 2px dashed red;
      z-index: 3;
      background-color: rgba(0,0,0,0.5);
      position: absolute;
      top: 0;
      left: 0;
      width: 133%;
      height: 66%;
      margin: 30px 0px 0px -9px;
    `,
    hideScrollbar: css`
      overflow-y: scroll;
      ::-webkit-scrollbar { /* WebKit */
        width: 0;
        height: 0;
        display: none;
      }
      scrollbar-width: none; /* Firefox */
    `,
    navClosedPadding: css`
      padding-left: 16px;
    `,
    navOpenPadding: css`
      padding-left: 160px;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        padding-left: 16px;
      }
    `,
    loadingContainer: css`
      position: absolute;
      z-index: 4;
      top: 0px;
      left 0px;
      width: 100%;
      height: 100%;
      background-color: grey;
      transition: padding-left ease 0.4s, opacity ease 2.5s;
    `,
  };
};

RiverScreen.propTypes = {
  navbarClosed: PropTypes.bool.isRequired,
  fetchPLCs: PropTypes.func.isRequired,
  fetchLocation: PropTypes.func.isRequired,
  setIOValue: PropTypes.func.isRequired,
  locations: PropTypes.shape({
    locations: PropTypes.shape({}),
  }),
  plcs: PropTypes.shape({
    plcs: PropTypes.shape({}),
  }),
};

RiverScreen.defaultProps = {
  locations: null,
  plcs: null,
};

const mapStateToProps = (state) => ({
  navbarClosed: state.nav.navbarClosed,
  locations: state.locations,
  plcs: state.plcs,
});

export default connect(mapStateToProps, { fetchLocation, setIOValue, fetchPLCs })(RiverScreen);
